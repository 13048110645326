import React from 'react'

export const IndustrySection = () => {
  const IndustryTags = () => {
    return (
      <>
        <span className="tag mt-2 is-small is-info is-light">Pharmacies</span>
        <span className="tag mt-2 is-info is-light">Restaurants</span>
        <span className="tag mt-2 is-info is-light">Takeaway shops</span>
        <span className="tag mt-2 is-info is-light">Medical Centers</span>
        <span className="tag mt-2 is-info is-light">Photography</span>
        <span className="tag mt-2 is-info is-light">Events venues</span>
        <span className="tag mt-2 is-info is-light">Ticketing offices</span>
        <span className="tag mt-2 is-info is-light">Hospital</span>
        <span className="tag mt-2 is-info is-light">Pick up places</span>
        <span className="tag mt-2 is-info is-light">Retail shops</span>
        <span className="tag mt-2 is-info is-light">Bar / Night clubs</span>
        <span className="tag mt-2 is-info is-light">Outdoor venues</span>
      </>
    )
  }
  return (
    <section id="industry" className="section section--gradient is-light-grey">
      <div className="container section">
        <p className="title has-text-centered mb-3 is-size-2 is-size-3-mobile ">
          Ideal solution for businesses
        </p>

        <div className="columns is-vcentered  is-centered">
          <div className="column is-6 is-12-mobile">
            <div className="has-text-centered pt-5 is-medium">
              <div className="tags are-large is-hidden-mobile">
                <IndustryTags/>
              </div>
              <div className="tags are-medium is-hidden-desktop">
                <IndustryTags/>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
