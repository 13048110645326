import React from "react";

export const TabContentSection = (props) => {
  return (
    <>
      <section className={`mt-2 mb-5  ${props.isBoxed ? 'box mx-1 is-shadowless has-border-grey' : ''}`}>
        <div className="heading px-1 has-text-weight-semibold">{props.title}</div>
        {props.children}
      </section>
    </>
  );
};
