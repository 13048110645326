import React, { useState } from "react";
import { useUser } from "../hooks";
import { ServiceLayout } from "../components/Layout";
import { TabsHeader } from "../components/TabsHeader";
import { 
  ServiceInfo, WaitListFields, WaitListPublicLink 
} from "../components/ServiceSettings";

export const ServiceSettingsPage = () => {
  const { user } = useUser();
  const sid = user.profile.queues.items[0].code;

  const [tabIndex, setTabIndex] = useState(0);

  const Content = () => {
    return (
      <>
        <TabsHeader
          tabNames={["GENERAL", "PUBLIC LIST", "NOTIFICATION"]}
          onTabChanged={(i) => setTabIndex(i)}
          activeTabIndex={tabIndex}
        />
        {tabIndex === 0 && <General sid={sid} />}
        {tabIndex === 1 && <WebLink sid={sid} />}
      </>
    );
  };

  return (
    <ServiceLayout title="Service Settings" activeNavIndex={1}>
      <Content />
    </ServiceLayout>
  );
};

const General = ({ sid }) => {
  return (
    <>
      <ServiceInfo sid={sid} />
      <WaitListFields sid={sid} />
    </>
  );
};

const WebLink = ({ sid }) => {
  return (
    <>
      <WaitListPublicLink sid={sid} />
    </>
  )
};
