import { Auth } from "aws-amplify";

export async function currentAuthenticatedUser(onSuccess, onError) {
  return Auth.currentAuthenticatedUser()
    .then((cognitoUser) => onSuccess(cognitoUser))
    .catch((err) => onError(err));
}

export async function isUserNotLoggedIn(isNotLoggedIn) {
  const isLoggedIn =
    (await Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => false)) &&
    (await Auth.currentSession()
      .then(() => true)
      .catch(() => false));

  if (!isLoggedIn) {
    isNotLoggedIn();
  }
}

export async function signOut(onSuccess) {
  Auth.signOut().then(() => onSuccess());
}

export async function signIn(username, password, onSuccess, onError) {
  return Auth.signIn(username, password)
    .then((cognitoUser) => onSuccess(cognitoUser))
    .catch((err) => onError(err));
}

export async function signUp(username, password, email, onError) {
  return await Auth.signUp({
    username,
    password,
    attributes: { email },
  }).catch((err) => onError(err));
}

export async function confirmSignUp(
  username,
  password,
  confirmationCode,
  onSuccess,
  onError
) {
  return Auth.confirmSignUp(username, confirmationCode)
    .then(async () => {
      await signIn(username, password, onSuccess, onError);
    })
    .catch((err) => onError(err));
}
