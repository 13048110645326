import React from "react";
import { ServiceLayout } from "../components/Layout";
import { LoadingSpinner } from "../components/Loading";
import { AddQueuerModalController, QueueTableContainer, UpdateQueuerModalController } from "../components/QueueTable";
import { useUser, useModalContext } from "../hooks";
import { useFetchServiceQueueData } from "../hooks/useFetchServiceQueueData";

import { ActionButton } from "../components/QueueTable/ActionButton";
import { animateScroll as scroll } from "react-scroll";

export const ServicePage = (props) => {
  const { user } = useUser();
  const sid = user.profile.queues.items[0].code;

  const { serviceQueue, isLoading } = useFetchServiceQueueData({ sid });
  const { openModal } = useModalContext();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ServiceLayout
      title="Waitlist"
      subtitle={serviceQueue.name}
      activeNavIndex={0}
    >
      <div className="box pt-0">
        <QueueTableContainer
          queueId={serviceQueue.id}
          user={props.user}
          onEdit={(queueItem) =>
            openModal({ name: "EDIT_QUEUE_ITEM", data: queueItem })
          }
        />

        <div className="has-text-right m-2">
          <ActionButton buttonStyles="is-outlined is-medium is-minwidth-300" 
              onActionClick={()=> openModal({
                name: "ADD_QUEUE_ITEM",
                data: {user: user, queueId: serviceQueue.id}
              })}>
            <span className="has-text-weight-bold">+ Add</span>
          </ActionButton>
        </div>
</div>
        <AddQueuerModalController onAdded={() => scroll.scrollToBottom()}/>
        <UpdateQueuerModalController />
    </ServiceLayout>
  );
};
