import { useContext } from "react";
import { ModalContext } from "../context";

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error(
      "`useModalContext` hook must be used within a `ModalContextProvider` component"
    );
  }
  return { ...context };
};
