import React, { useCallback } from "react";
import { updateQueueItem } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { QueueItemForm } from "./QueueItemForm";

export const UpdateQueuerModal = (props) => {
  const { show, queuerData, onClose, onUpdated, onError } = props;

  const save = useCallback(
    async (name, note, email, phone, expectedDate, onSaved, onError) => {
      const input = {
        id: queuerData.id,
        queuer: name,
        note,
        email,
        phone,
        expectedDate,
      };

      return await API.graphql(graphqlOperation(updateQueueItem, { input }))
        .then(() => {
          onSaved();
        })
        .catch((err) => {
          onError(err);
        });
    },
    [queuerData]
  );

  const UpdateForm = () => {
    const onSubmit = (values, setSubmitting, resetForm) => {
      const { name, phone, email, note, expected } = values;

      if (
        name === queuerData.queuer &&
        phone === queuerData.phone &&
        email === queuerData.email &&
        note === queuerData.note &&
        expected === queuerData.expectedDate
      ) {
        onClose();
      } else {
        save(
          name,
          note,
          email,
          phone,
          expected,
          () => {
            setSubmitting(false);
            resetForm();
            onUpdated(name);
          },
          (error) => {
            console.log({ error });
            setSubmitting(false);
            onError(name);
          }
        );
      }
    };

    const expectedDateTokens = queuerData.expectedDate
      ? queuerData.expectedDate.split("-")
      : null;

    const initialValues = {
      name: queuerData.queuer,
      note: queuerData.note,
      phone: queuerData.phone,
      email: queuerData.email,
      date:
        expectedDateTokens && expectedDateTokens[0]
          ? expectedDateTokens[0].trim()
          : null,
      time:
        expectedDateTokens && expectedDateTokens[1]
          ? expectedDateTokens[1].trim()
          : "",
    };

    return (
      <QueueItemForm
        title="Update"
        buttonName="Save"
        onFormSubmit={(values, setSubmitting, resetForm) =>
          onSubmit(values, setSubmitting, resetForm)
        }
        onClose={onClose}
        initialValues={initialValues}
      />
    );
  };

  return (
    <div className={`modal ${show ? `is-active` : ""}`}>
      <div className="modal-background"></div>
      <UpdateForm />
    </div>
  );
};
