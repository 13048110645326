import React from "react";
import { Redirect, Route } from "react-router-dom";
import { LoadingText } from "../components/Loading";
import { useUser } from "../hooks";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, ensureUserIsLoggedIn } = useUser();

  ensureUserIsLoggedIn();

  const RedirectToLogin = () => {
    return <Redirect to="/login" />;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          user.isLoading ? (
            <LoadingText />
          ) : (
            <Component user={user} {...props} {...rest} />
          )
        ) : (
          <RedirectToLogin />
        )
      }
    />
  );
};

export default PrivateRoute;
