/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:6dbc8832-2eed-4946-bee2-033e3433ebbe",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_O8ON3LLku",
    "aws_user_pools_web_client_id": "7pa79klcf9ljnss62fion70ml2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qkpt76uelzdzfitxy6xbyyc234.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "queueboystripewebhootapi",
            "endpoint": "https://rjazgy80mb.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
