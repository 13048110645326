import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import Amplify from "aws-amplify";
import config from "./aws-exports";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import Routes from "./route/routes";
import { ErrorBoundary } from "react-error-boundary";
import { ToastProvider } from "react-toast-notifications";

import "./assets/sass/styles.sass";
import "./assets/sass/styles.scss";

import { ModalContextProvider, UserProvider } from "./context";
import { UnexpectedErrorPage } from "./pages";
import { ThemeProvider } from "@ui5/webcomponents-react/lib/ThemeProvider";

import "@ui5/webcomponents/dist/Assets.js";
import "@ui5/webcomponents-react/dist/Assets";
import "react-app-polyfill/ie11";
import "@ui5/webcomponents-base/dist/features/browsersupport/IE11WithWebComponentsPolyfill";
import "@ui5/webcomponents-react-base/polyfill/IE11";

TimeAgo.addDefaultLocale(en);

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={UnexpectedErrorPage}
          onError={(err) => console.error(err)}
        >
          <ModalContextProvider>
            <ThemeProvider>
              <ToastProvider>
                <Routes />
              </ToastProvider>
            </ThemeProvider>
          </ModalContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
