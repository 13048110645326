import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { updateQueueItem } from "../../graphql/mutations";
import { useUser } from "../../hooks";

export const StatusUpdateButton = ({ id, status, name }) => {
  const statusTitle = status === 2 ? "READY" : "COLLECTED";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { ensureUserIsLoggedIn } = useUser();
  const { addToast } = useToasts();
  const buttonRef = useRef(null);

  const updateStatus = useCallback(
    async (onSaved, onError) => {
      let input;

      if (status === 2) {
        input = {
          id,
          status: status,
          readyOn: new Date().toISOString(),
        };
      } else {
        input = {
          id,
          status: status,
          collectedOn: new Date().toISOString(),
        };
      }

      return await API.graphql(graphqlOperation(updateQueueItem, { input }))
        .then(() => {
          onSaved();
        })
        .catch((err) => {
          onError(err);
        });
    },
    [id, status]
  );

  return (
    <button
      className={`button is-info is-outlined is-small ${
        isSubmitting ? "is-loading" : ""
      }`}
      style={styles.button}
      ref={buttonRef}
      onClick={() => {
        setIsSubmitting(true);
        ensureUserIsLoggedIn();

        updateStatus(
          () => {
            setIsSubmitting(false);
            addToast(name + " is " + statusTitle, {
              appearance: "info",
              autoDismiss: true,
            });
            buttonRef.current.blur();
          },
          (error) => {
            console.log({ error });
            setIsSubmitting(false);
            addToast("Failed to update status of " + name, {
              appearance: "error",
              autoDismiss: true,
            });
            buttonRef.current.blur();
          }
        );
      }}
    >
      {statusTitle}
    </button>
  );
};

const styles = {
  button: {
    width: "100px",
  },
};
