import React, { useEffect } from 'react'
import Footer from '../Footer'
import { NavBar } from '../NavBar'

import AOS from "aos";
import "aos/dist/aos.css";

export const Layout = props => {
  useEffect(() => {
    AOS.init({duration : 2000  });
    AOS.refresh();
  }, []);

  return (
    <div id="layout-wrapper">
      <NavBar />
        <div id="content-wrapper">{props.children}</div>
      <Footer />
    </div>
  )
}
