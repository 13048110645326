export const MandatoryLabel = ({ label, className="" }) => {
  return (
    <label className={`label ${className}`}>
      {label}
      <span className="has-text-danger"> *</span>
    </label>
  )
}

export const OptionalLabel = ({ label }) => {
  return <label className="label">{label}</label>
}
