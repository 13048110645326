import React, { useState } from "react";
import { ServiceLayout } from "../components/Layout";
import { TabsHeader } from "../components/TabsHeader";
import { useUser } from "../hooks";
import {
  GeneralAccountSettings,
  BusinessAccountSettings,
} from "../components/AccountSettings";

export const AccountPage = () => {
  const { user } = useUser();
  const [tabIndex, setTabIndex] = useState(0);

  const Content = () => {
    return (
      <>
        <TabsHeader
          tabNames={["GENERAL", "BUSINESS"]}
          onTabChanged={(i) => setTabIndex(i)}
          activeTabIndex={tabIndex}
        />
        {tabIndex === 0 && <GeneralAccountSettings user={user} />}
        {tabIndex === 1 && <BusinessAccountSettings user={user} />}
      </>
    );
  };

  return (
    <ServiceLayout 
        title="Account Settings"
        activeNavIndex={1}>
      <Content />
    </ServiceLayout>
  );
};
