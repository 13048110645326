/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput) {
    createStripeCustomer(input: $input) {
      customerStripeId
    }
  }
`;
export const createStripeCustomerPortalUrl = /* GraphQL */ `
  mutation CreateStripeCustomerPortalUrl($input: StripeCustomerPortalUrlInput) {
    createStripeCustomerPortalUrl(input: $input) {
      customerBillingPortalUrl
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      address
      phone
      logoUrl
      code
      owner
      customerStripeId
      plan
      createdAt
      updatedAt
      queues {
        items {
          id
          name
          code
          serviceQueueBusinessId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      address
      phone
      logoUrl
      code
      owner
      customerStripeId
      plan
      createdAt
      updatedAt
      queues {
        items {
          id
          name
          code
          serviceQueueBusinessId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      address
      phone
      logoUrl
      code
      owner
      customerStripeId
      plan
      createdAt
      updatedAt
      queues {
        items {
          id
          name
          code
          serviceQueueBusinessId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createServiceQueue = /* GraphQL */ `
  mutation CreateServiceQueue(
    $input: CreateServiceQueueInput!
    $condition: ModelServiceQueueConditionInput
  ) {
    createServiceQueue(input: $input, condition: $condition) {
      id
      name
      code
      serviceQueueBusinessId
      createdAt
      updatedAt
      business {
        id
        name
        address
        phone
        logoUrl
        code
        owner
        customerStripeId
        plan
        createdAt
        updatedAt
        queues {
          nextToken
        }
      }
      owner
      items {
        items {
          id
          queuer
          status
          note
          phone
          email
          expectedDate
          queuedOn
          readyOn
          collectedOn
          code
          queueItemQueueId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateServiceQueue = /* GraphQL */ `
  mutation UpdateServiceQueue(
    $input: UpdateServiceQueueInput!
    $condition: ModelServiceQueueConditionInput
  ) {
    updateServiceQueue(input: $input, condition: $condition) {
      id
      name
      code
      serviceQueueBusinessId
      createdAt
      updatedAt
      business {
        id
        name
        address
        phone
        logoUrl
        code
        owner
        customerStripeId
        plan
        createdAt
        updatedAt
        queues {
          nextToken
        }
      }
      owner
      items {
        items {
          id
          queuer
          status
          note
          phone
          email
          expectedDate
          queuedOn
          readyOn
          collectedOn
          code
          queueItemQueueId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteServiceQueue = /* GraphQL */ `
  mutation DeleteServiceQueue(
    $input: DeleteServiceQueueInput!
    $condition: ModelServiceQueueConditionInput
  ) {
    deleteServiceQueue(input: $input, condition: $condition) {
      id
      name
      code
      serviceQueueBusinessId
      createdAt
      updatedAt
      business {
        id
        name
        address
        phone
        logoUrl
        code
        owner
        customerStripeId
        plan
        createdAt
        updatedAt
        queues {
          nextToken
        }
      }
      owner
      items {
        items {
          id
          queuer
          status
          note
          phone
          email
          expectedDate
          queuedOn
          readyOn
          collectedOn
          code
          queueItemQueueId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createQueueItem = /* GraphQL */ `
  mutation CreateQueueItem(
    $input: CreateQueueItemInput!
    $condition: ModelQueueItemConditionInput
  ) {
    createQueueItem(input: $input, condition: $condition) {
      id
      queuer
      status
      note
      phone
      email
      expectedDate
      queuedOn
      readyOn
      collectedOn
      code
      queueItemQueueId
      createdAt
      updatedAt
      queue {
        id
        name
        code
        serviceQueueBusinessId
        createdAt
        updatedAt
        business {
          id
          name
          address
          phone
          logoUrl
          code
          owner
          customerStripeId
          plan
          createdAt
          updatedAt
        }
        owner
        items {
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateQueueItem = /* GraphQL */ `
  mutation UpdateQueueItem(
    $input: UpdateQueueItemInput!
    $condition: ModelQueueItemConditionInput
  ) {
    updateQueueItem(input: $input, condition: $condition) {
      id
      queuer
      status
      note
      phone
      email
      expectedDate
      queuedOn
      readyOn
      collectedOn
      code
      queueItemQueueId
      createdAt
      updatedAt
      queue {
        id
        name
        code
        serviceQueueBusinessId
        createdAt
        updatedAt
        business {
          id
          name
          address
          phone
          logoUrl
          code
          owner
          customerStripeId
          plan
          createdAt
          updatedAt
        }
        owner
        items {
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteQueueItem = /* GraphQL */ `
  mutation DeleteQueueItem(
    $input: DeleteQueueItemInput!
    $condition: ModelQueueItemConditionInput
  ) {
    deleteQueueItem(input: $input, condition: $condition) {
      id
      queuer
      status
      note
      phone
      email
      expectedDate
      queuedOn
      readyOn
      collectedOn
      code
      queueItemQueueId
      createdAt
      updatedAt
      queue {
        id
        name
        code
        serviceQueueBusinessId
        createdAt
        updatedAt
        business {
          id
          name
          address
          phone
          logoUrl
          code
          owner
          customerStripeId
          plan
          createdAt
          updatedAt
        }
        owner
        items {
          nextToken
        }
      }
      owner
    }
  }
`;
export const createNotificationHistory = /* GraphQL */ `
  mutation CreateNotificationHistory(
    $input: CreateNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    createNotificationHistory(input: $input, condition: $condition) {
      id
      serviceID
      event
      email
      sms
      emailError
      smsError
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNotificationHistory = /* GraphQL */ `
  mutation UpdateNotificationHistory(
    $input: UpdateNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    updateNotificationHistory(input: $input, condition: $condition) {
      id
      serviceID
      event
      email
      sms
      emailError
      smsError
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotificationHistory = /* GraphQL */ `
  mutation DeleteNotificationHistory(
    $input: DeleteNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    deleteNotificationHistory(input: $input, condition: $condition) {
      id
      serviceID
      event
      email
      sms
      emailError
      smsError
      createdAt
      updatedAt
      owner
    }
  }
`;
