import React from "react";

export const TabsHeader = ({
  tabNames,
  activeTabIndex,
  onTabChanged,
}) => {
  const TabButton = ({ index, name }) => {
    return (
      <p className="control">
        <button
          className={`button is-small is-borderless ${activeTabIndex === index ? "is-primary" : ""}`}
          onClick={() => {
            if (activeTabIndex !== index) onTabChanged(index);
          }}
        >
          <span
            className={`${
              activeTabIndex === index ? "has-text-weight-bold" : ""
            }`}
          >
            {name}
          </span>
        </button>
      </p>
    );
  };

  return (
    <nav className="level mt-5 px-1 is-borderles">
              <div className="level-right">

        <div className="level-item">
          <div className="field has-addons">
            {tabNames.map((tab, i) => (
              <TabButton key={`tab_${i}`} index={i} name={tab} />
            ))}
          </div>
          </div>
        </div>
    </nav>
  );
};
