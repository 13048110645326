import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LoadingText } from "../components/Loading";
import { CreateProfileModal } from "../components/Profile";
import { useUser } from "../hooks";

import {
  HomePage,
  NotFoundPage,
  LoginPage,
  ServicePage,
  UpgradePage,
  TermsPage,
  PrivacyPage,
  ServiceSettingsPage,
  AccountPage,
} from "../pages";

import PrivateRoute from "./private-route";

const Routes = () => {
  const { user } = useUser();
  
  if (user && user.isLoading) {
    return <LoadingText />;
  }
  if (user && !user.profile) {
    return <CreateProfileModal show={true} />;
  }

  return (
    <Switch>
      <Route exact path="/">
        {user ? <Redirect to="/service/waiting-list" /> : <HomePage />}
      </Route>

      <Route exact path="/login">
        {user ? <Redirect to="/" /> : <LoginPage />}
      </Route>

      <PrivateRoute exact path="/service/waiting-list" component={ServicePage} />
      <PrivateRoute exact path="/service/settings" component={ServiceSettingsPage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/upgrade" component={UpgradePage} />

      <Route exact path="/terms" component={TermsPage} />
      <Route exact path="/privacy" component={PrivacyPage} />
      <Route exact path="/404" component={NotFoundPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
