import React from "react";
import { Layout } from "../components/Layout/Layout";
import { PricingSection } from "../sections/pricing";

export const UpgradePage = (props) => {
  return (
    <Layout>
      <PricingSection currentPlan={props.user.profile.plan} />
    </Layout>
  );
};
