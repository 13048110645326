import { API, graphqlOperation } from "aws-amplify";
import React, { useState } from "react";

import { createStripeCustomerPortalUrl } from "../../graphql/mutations";
import { useUser } from "../../hooks";

export const UpgradePlanButton = ({ currentPlan }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, ensureUserIsLoggedIn } = useUser();

  const upgrade = async () => {
    const input = {
      customerStripeId: user.profile.customerStripeId,
    };
  
    await API.graphql(
      graphqlOperation(createStripeCustomerPortalUrl, { input })
    ).then((response) => {
      if (!response || !response.data.createStripeCustomerPortalUrl) {
        upgrade();
      } else {
        const customerBillingPortalUrl =
          response.data.createStripeCustomerPortalUrl.customerBillingPortalUrl;

        window.location.href = customerBillingPortalUrl.replace(
          /^"(.*)"$/,
          "$1"
        );
      }
    });
   
  };

  return (
    <button
      className="button is-fullwidth is-info"
      disabled={isSubmitting}
      onClick={() => {
        ensureUserIsLoggedIn();
        setIsSubmitting(true);
        upgrade();
      }}
    >
      UPGRADE
    </button>
  );
};
