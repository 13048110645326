import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks";
import ScrollLink from "../ScrollLink/ScrollLink";

export const GuestMenu = () => {
  return (
    <div className="navbar-end">
      <ScrollLink className="navbar-item" to="intro" title="Home" />
      <ScrollLink className="navbar-item" to="features" title="Features" />
      <ScrollLink className="navbar-item" to="pricing" title="Pricing" />

      <div className="navbar-item">
        <div className="field is-grouped">
          <p className="control">
            <Link className="button is-primary" to="/login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export const UserMenu = ({ user, handleSignout }) => {
  return (
    <div className="navbar-end">
      {user && user.profile.plan.name !== "ELITE_PLAN" && (
        <Link className="navbar-item" to="/upgrade">
          <span className="tag is-warning">UPGRADE</span>
        </Link>
      )}

      <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link">Service</a>

        <div className="navbar-dropdown">
          <Link className="navbar-item" to="/service/waiting-list">
            Waiting List
          </Link>
          <Link className="navbar-item" to="/service/settings">
            Settings
          </Link>
        </div>
      </div>

      <Link className="navbar-item" to="/account">
        Account
      </Link>

      <div className="navbar-item">
        <div className="field is-grouped">
          <p className="control">
            <span
              className="button is-primary is-outlined"
              onClick={handleSignout}
            >
              Logout
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const toggleNavbar = () => setIsActive(!isActive);

  const { logout, user } = useUser();

  return (
    <nav
      className="navbar is-fixed-top has-border-bottom-grey"
      style={{ zIndex: 80 }}
      aria-label="navigation"
      role="navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <span className="has-text-centered">
              <span className="logo title is-4">QBoy</span>
              {user && user.profile.name &&
                <span className="is-4 pt-1 pl-0 heading has-text-weight-semibold has-text-grey ellipsis-overflow"
                  style={{width: "250px"}}>
                  {user.profile.name}
                </span>
              } 
            </span>
          </Link>

          <div
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-expanded="false"
            data-target="navMenu"
            onClick={toggleNavbar}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          id="navMenu"
        >
          {user ? (
            <UserMenu user={user} handleSignout={logout} />
          ) : (
            <GuestMenu />
          )}
        </div>
      </div>
    </nav>
  );
};
