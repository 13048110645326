import React from "react";
import { Link } from "react-router-dom";
import { TabContentSection } from "../TabsHeader";

export const GeneralAccountSettings = ({ user }) => {
  return (
    <>
      <TabContentSection title="Email" isBoxed>
        <div className="field control">
          <input
            className="input"
            type="email"
            name="email"
            id="email"
            value={user.email}
            disabled={true}
            readOnly={true}
          />
        </div>
      </TabContentSection>

      <TabContentSection title="Current Plan" isBoxed>
        <div className="field control">
          <input
            className="input"
            type="text"
            name="plan"
            id="plan"
            value={user.profile.plan.name.replace("_PLAN", "")}
            readOnly={true}
          />
        </div>

        {user.profile.plan.name !== "FREE_PLAN" && (
          <Link className="is-underline pt-4 is-size-7" to="/cancel-plan">
            Cancel current plan
          </Link>
        )}

        {user.profile.plan.name === "FREE_PLAN" && (
          <div className="buttons is-right py-4">
            <Link className="button is-warning" to="/upgrade">
              UPGRADE
            </Link>
          </div>
        )}

        {user.profile.plan.name !== "FREE_PLAN" && (
          <div className="buttons is-right py-4">
            <Link className="button is-info" to="/upgrade">
              MANAGE
            </Link>
          </div>
        )}
      </TabContentSection>
    </>
  );
};
