import React from "react";
import AuthForm from "../components/AuthForm";
import { BareNavBar } from "../components/NavBar/bare-navbar";

export const LoginPage = () => {
  return (
    <>
      <BareNavBar />

      <section className="hero is-fullheight">
        <div className="hero-body is-paddingless">
          <div className="container">
            <div className="column is-4 is-offset-4">
              <AuthForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
