import React from "react";

import QueueIMG from "../assets/img/queues.jpg";

const SectionRightContent = (props) => {
  return (
    <div className="section">
      <div className="columns is-vcentered reverse-row-order ">
        <div className="column">
          <h1 className="title is-size-2 is-size-4-mobile has-text-centered-mobile">
            {props.title}
          </h1>
          <div className="has-text-black paragraph is-size-5 is-size-6-mobile has-text-justified-mobile">
            {props.children}
          </div>
        </div>
        <div className="column has-text-centered-mobile">{props.image}</div>
      </div>
    </div>
  );
};

const SectionLeftContent = (props) => {
  return (
    <div className="section">
      <div className="columns is-vcentered reverse-row-order">
        {props.image}
        <div className="column">
          <h1 className="title is-size-2 is-size-4-mobile has-text-centered-mobile">
            {props.title}
          </h1>
          <div className="has-text-black paragraph is-size-5 is-size-6-mobile has-text-justified-mobile">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionSeparator = () => {
  return <div style={{ height: "3em" }}></div>;
};

const FeatureSection = () => {
  return (
    <section id="features" className="hero is-bold is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <SectionRightContent
            title="Unique Melbourne-based Queuing System Solution"
            image=""
          >
            We aim to be the most affordable and best value for small businesses
            to use as far as Melbourne providers are concerned. If there is a
            cheaper option based in Melbourne, we are happy to beat them 5%,
            just let us know. In addition, our easy to set-up within minutes for
            the businesses to have an account with us and start using our system
            is priceless.
          </SectionRightContent>

          <SectionSeparator />

          <SectionLeftContent
            title="COVID-19 Social Distancing Solution"
            image={
              <div className="column">
                <figure className="image is4by3 mt-2 has-image-centered">
                  <img
                    src={QueueIMG}
                    alt="COVID-19 Social Distancing Solution"
                  />
                </figure>
              </div>
            }
          >
            There is only so much space a busy tiny shop can implement the
            social distancing of 1.5m in their premises. Hence, our 100% digital
            queuing system resolves this problem by letting clients of the
            businesses wait in a location they choose and enjoy their time while
            the business prepares their product or until their turn is up for an
            appointment. This is all possible through viewing the LIVE waiting
            status which can be accessed in any mobile phone anywhere and prompt
            the client to act accordingly.
          </SectionLeftContent>

          <SectionSeparator />

          <SectionRightContent title="Quick, Easy and Efficient" image="">
            No downloads &amp; No accounts needed for users. No hardware needed
            for businesses! Our web-based application is user friendly and no
            fuss. This means no need to download an App or any software. It is
            just one click away using the landing page of the business and you
            will see your position in the queue. In addition, user does not need
            to create an account, they just need to visit the link the business
            provided to see their position in the queue and the waiting time.
          </SectionRightContent>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
