import React from "react";

export const ActionButton = (props) => {
    const { onActionClick, buttonStyles } = props;

    return (
        <button
        className={`button ${buttonStyles}`}
        onClick={onActionClick}
      >
        <span className="icon is-small">
            {props.children}
        </span>
      </button>
    )
}
