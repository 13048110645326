import React from 'react'
import { Layout } from '../components/Layout/Layout'

import FeatureSection from '../sections/features'
import { GetStartedSection } from '../sections/getstarted'
import { IndustrySection } from '../sections/industry'
import { IntroSection } from '../sections/intro'
import { PricingSection } from '../sections/pricing'

export const HomePage = () => {
  return (
    <Layout>
      <IntroSection />
      <FeatureSection />
      <IndustrySection />
      <PricingSection/>
      <GetStartedSection />
    </Layout>
  )
}
