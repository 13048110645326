import React from 'react'
import { Link } from 'react-router-dom'

export const BareNavBar = () => {
  return (
    <nav
      className="navbar is-fixed-top has-border-bottom-grey"
      style={{ zIndex: 80 }}
      aria-label="navigation"
      role="navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <span className="logo title is-4">QBoy</span>
          </Link>
        </div>
      </div>
    </nav>
  )
}
