import { API, graphqlOperation } from "aws-amplify";
import { createStripeCustomer } from "../../graphql/mutations";

export const FREE_PLAN = "price_1HvC99HzpvYGmo9nFcjszmpE";

export async function createFreePlan(username, email) {
  const input = {
    username,
    email,
    planId: FREE_PLAN,
  };
  return await API.graphql(graphqlOperation(createStripeCustomer, { input }))
    .then((response) => {
      const stripeId = response.data.createStripeCustomer.customerStripeId;

      return {
        customerStripeId: stripeId,
        plan: {
          name: "FREE_PLAN",
        },
      };
    })
    .catch((err) => {
      throw err;
    });
}
