import React from "react";
import { Layout } from "../components/Layout/Layout";

export const PrivacyPage = () => {
  return (
    <Layout>
      <div>
        <section className="section section--gradient">
          <div className="container">
            <br />
            <p className="title mb-1 has-text-grey">Privacy Policy</p>
          </div>

          <div className="container">
            <div>
              <br />
              <p className="is-size-6">
                Our website www.qboy.com.au will only collect, store or use your
                information to enhance the services we do provide to you.
              </p>

              <br />
              <p className="is-size-6">
                These information may include the following but is not limited
                to:
              </p>
              <ul>
                <li>Full name</li>
                <li>Email address</li>
                <li>Business address</li>
                <li>Contact number</li>
                <li>Company logo</li>
              </ul>

              <br />
              <p className="is-size-6">
                - We would never sell any of your information to any company.
                However, we may disclose any data to a third party in case there
                is a need as to the service you may need.
              </p>

              <br />
              <p className="is-size-6">
                - The businesses may also post your first name on the premises
                television screen to deliver the waiting list to the public.
              </p>

              <br />
              <p className="is-size-6">
                - The businesses will have access to the statistics of how long
                you have waited to improve their services to you next time. The
                businesses will have all the data you provided to them and there
                is no way our company would be able to control how they use it.
              </p>

              <br />
              <p className="is-size-6">
                - We may also need to release data in case there is a legal
                order by state or federal law.
              </p>

              <br />
              <p className="is-size-6">
                - We may verify the information you give to us in relation to
                the service we provide to you.
              </p>

              <br />
              <p className="is-size-6">
                - We will do our best to protect your personal information from
                any misuse, loss or unauthorised access.
              </p>

              <br />
              <p className="is-size-6">
                - Links are provided on our website and we are not responsible
                for the linked website’s privacy practices and we may NOT be
                affiliated to them. The links were provided as we believe it may
                be of use to you or the business establishments that availed our
                services.
              </p>

              <br />
              <p className="is-size-6">
                - We do not keep any credit card details as payment will be
                directed and processed by stripe.com or Stripe. You may need to
                check their privacy policy.
              </p>

              <br />
              <p className="is-size-6">
                - A third party may indirectly collect, store or use your data
                by affiliating with us. As such, this privacy policy only covers
                our own and not that of the third party.
              </p>

              <br />
              <p className="is-size-6">
                - Due to the use of internet and phone line as means of
                communication and transmission of data, we can not guarantee the
                security of your information at all times. However, in case of
                any breach of privacy, we will notify you as soon as possible.
              </p>

              <br />
              <p className="is-size-6">
                - We will also only collect, store or use the minimum
                information needed to give you the best service that you may
                require.
              </p>

              <br />
              <p className="is-size-6">
                - Lastly, you release us of any liabilities from any losses,
                damages, expenses, moral, physical, emotional or any type of
                harm, loss or burden you may incur arising directly, indirectly,
                incidental, consequential or however caused from collecting,
                storing or using your personal information or any situations in
                scope of this privacy policy.
              </p>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p>
                If you have any concerns regarding this privacy policy, please
                send an email to{" "}
                <a
                  href="mailto:qboy.au@gmail.com"
                  target="_blank"
                  title="Email us"
                  rel="noreferrer"
                >
                  <span>qboy.au@gmail.com</span>
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
