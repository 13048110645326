import React from "react";
import { Link } from "react-router-dom";
import { UpgradePlanButton } from "../components/UpgradePlanButton";

export const PricingSection = ({ currentPlan }) => {
  return (
    <section
      id="pricing"
      className="section section--gradient is-white is-medium"
    >
      <div className="container">
        {!currentPlan && (
          <p className="title has-text-centered mb-5 is-size-2 is-size-3-mobile">
            Pricing
          </p>
        )}

        {currentPlan && (
          <div className="has-text-centered mb-6">
            <h1 className="title has-text-weight-semibold is-size-3">
              We have more to offer.
            </h1>
            <h2 className="subtitle mt-1 is-size-5">
              Great services at cheap plan
            </h2>
          </div>
        )}

        <div className="columns is-vcentered is-centered">
          <div className="column is-6 is-12-mobile">
            <div className="pricing-table">
              <div className="pricing-plan">
                <div className="plan-header">Free</div>
                <div className="plan-price">
                  <span className="plan-price-amount">
                    <span className="plan-price-currency">$</span>0
                  </span>
                </div>
                <div className="plan-items">
                  <div className="plan-item">10 waitlisted guests per day</div>
                  <div className="plan-item">24/7 Customer Status Viewing</div>
                  <div className="plan-item">Mobile friendly</div>
                  <div className="plan-item">Customer support by email</div>
                  <div className="plan-item">--</div>
                </div>
                <div className="plan-footer">
                  {!currentPlan && (
                    <Link className="button is-fullwidth is-info" to="/login">
                      Get Started
                    </Link>
                  )}

                  {currentPlan && currentPlan.name !== "ELITE_PLAN" && (
                    <span className="button is-fullwidth ">Current Plan</span>
                  )}
                </div>
              </div>

              <div className="pricing-plan is-success">
                <div className="plan-header">Elite</div>
                <div className="plan-price">
                  <span className="plan-price-amount">
                    <span className="plan-price-currency">$</span>39
                  </span>
                  /month
                </div>
                <div className="plan-items">
                  <div className="plan-item">Unlimited waitlisted guests</div>
                  <div className="plan-item">24/7 Customer Status Viewing</div>
                  <div className="plan-item">Mobile friendly</div>
                  <div className="plan-item">Customer support by email</div>
                  <div className="plan-item">Customer support by chat box</div>
                </div>
                <div className="plan-footer">
                  {!currentPlan && (
                    <Link className="button is-fullwidth is-info" to="/login">
                      Get Started
                    </Link>
                  )}
                  {currentPlan && currentPlan.name === "ELITE_PLAN" && (
                    <span className="button is-fullwidth ">Current Plan</span>
                  )}
                  {currentPlan && currentPlan.name !== "ELITE_PLAN" && (
                    <UpgradePlanButton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
