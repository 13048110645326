import React from "react";
import { Link } from "react-scroll";

export const ScrollLink = ({ to, title, className }) => {
  return (
    <Link
      className={className}
      to={to}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
    >
      {title}
    </Link>
  );
};

export default ScrollLink;

