import { useEffect, useReducer } from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  ON_CREATE,
  ON_UPDATE,
  ON_DELETE,
} from "./constants";
import { STATUS_COLLECTED } from "../utils/status";
import { startDate, datesAreOnSameDay } from "../utils";

const listServiceQueueItemsByServiceQueueId = /* GraphQL */ `
  query listServiceQueueItemsByServiceQueueId($serviceQueueID: ID!) {
    listQueueItems(
      filter: {
        queueItemQueueId: { eq: $serviceQueueID }
        and: { status: { lt: 3 } }
      }
    ) {
      items {
        id
        queuer
        code
        status
        note
        phone
        email
        expectedDate
        queuedOn
        readyOn
        collectedOn
      }
    }
  }
`;

const listServiceQueueItemsByServiceQueueIdAndCreatedAt = /* GraphQL */ `
  query listServiceQueueItemsByServiceQueueIdAndCreatedAt(
    $serviceQueueID: ID!
    $createdAt: String!
  ) {
    listQueueItems(
      filter: {
        queueItemQueueId: { eq: $serviceQueueID }
        and: { createdAt: { ge: $createdAt } }
      }
    ) {
      items {
        id
      }
    }
  }
`;

const onCreateQueueItemByServiceQueueId = /* GraphQL */ `
  subscription OnCreateQueueItemByServiceQueueId($serviceQueueID: ID) {
    onCreateQueueItemByServiceQueueID(queueItemQueueId: $serviceQueueID) {
      id
      queuer
      code
      status
      note
      phone
      email
      expectedDate
      queuedOn
      readyOn
      collectedOn
    }
  }
`;

const onUpdateQueueItemByServiceQueueId = /* GraphQL */ `
  subscription OnUpdateQueueItemByServiceQueueId($serviceQueueID: ID) {
    onUpdateQueueItemByServiceQueueID(queueItemQueueId: $serviceQueueID) {
      id
      queuer
      code
      status
      note
      phone
      email
      expectedDate
      queuedOn
      readyOn
      collectedOn
    }
  }
`;

const onDeleteQueueItem = /* GraphQL */ `
  subscription onDeleteQueueItem($owner: String) {
    onDeleteQueueItem(owner: $owner) {
      id
      queueItemQueueId
      queuedOn
    }
  }
`;

const sortItems = (items) => {
  return items.sort((a, b) => (a.queuedOn > b.queuedOn ? 1 : -1));
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        queueItems: sortItems(action.payload.queueItems),
        counter: action.payload.counter,
      };
    case ON_CREATE:
      let today = startDate();
      let counter = {};

      if (datesAreOnSameDay(today, state.counter.date)) {
        counter = {
          ...state.counter,
          totalWaitlisted: state.counter.totalWaitlisted + 1,
        };
      } else {
        counter = { date: today, totalWaitlisted: 1 };
      }

      return {
        ...state,
        queueItems: [...state.queueItems, action.payload],
        counter,
      };
    case ON_UPDATE: {
      const index = state.queueItems.findIndex(
        (item) => item.id === action.payload.id
      );
      const newArray = [...state.queueItems];

      if (action.payload.status === STATUS_COLLECTED) {
        newArray.splice(index, 1);
      } else {
        newArray[index] = action.payload;
      }
      return { ...state, queueItems: newArray };
    }

    case ON_DELETE: {
      let counter = state.counter;
      if (
        datesAreOnSameDay(
          state.counter.date,
          new Date(action.payload.queuedOn)
        )
      ) {
        counter = {
          ...state.counter,
          totalWaitlisted: state.counter.totalWaitlisted - 1,
        };
      }

      const index = state.queueItems.findIndex(
        (item) => item.id === action.payload.id
      );

      return {
        ...state,
        queueItems: [
          ...state.queueItems.slice(0, index),
          ...state.queueItems.slice(index + 1),
        ],
        counter,
      };
    }

    default:
      throw new Error();
  }
};

const initialState = {
  isLoading: true,
  isError: false,
  queueItems: [],
  counter: {},
};

const getTotalWaitlistedToday = async (serviceQueueID) => {
  const startOfToday = startDate();

  const totalWaitlisted = await API.graphql(
    graphqlOperation(listServiceQueueItemsByServiceQueueIdAndCreatedAt, {
      serviceQueueID,
      createdAt: startOfToday.toISOString(),
    })
  ).then((data) => data.data.listQueueItems.items.length);

  return { startOfToday, totalWaitlisted };
};

export const useFetchQueueItemsData = ({ serviceQueueID, owner }) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT });
      }

      try {
        const input = { serviceQueueID };
        const result = await API.graphql(
          graphqlOperation(listServiceQueueItemsByServiceQueueId, input)
        );

        const { startOfToday, totalWaitlisted } = await getTotalWaitlistedToday(
          serviceQueueID
        );

        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: {
            queueItems: result.data.listQueueItems.items,
            counter: {
              date: startOfToday,
              totalWaitlisted,
            },
          },
        });
      } catch (error) {
        if (isMounted) {
          console.error(error);
          dispatch({ type: FETCH_DATA_FAILURE });
        }
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [serviceQueueID]);

  useEffect(() => {
    let subscriber;

    const subscribeOnCreateQueueItem = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onCreateQueueItemByServiceQueueId, { serviceQueueID })
      );

      subscriber = await subscription.subscribe({
        next: (data) => {
          dispatch({
            type: ON_CREATE,
            payload: data.value.data.onCreateQueueItemByServiceQueueID,
          });
        },
        error: () => {
          subscriber?.unsubscribe();
          subscribeOnCreateQueueItem();
        },
      });
    };

    subscribeOnCreateQueueItem();

    return () => subscriber?.unsubscribe();
  }, [serviceQueueID]);

  useEffect(() => {
    let subscriber;

    const subscribeOnUpdateQueueItem = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onUpdateQueueItemByServiceQueueId, { serviceQueueID })
      );

      subscriber = await subscription.subscribe({
        next: (data) => {
          dispatch({
            type: ON_UPDATE,
            payload: data.value.data.onUpdateQueueItemByServiceQueueID,
          });
        },
        error: () => {
          subscriber?.unsubscribe();
          subscribeOnUpdateQueueItem();
        },
      });
    };

    subscribeOnUpdateQueueItem();

    return () => subscriber?.unsubscribe();
  }, [serviceQueueID]);

  useEffect(() => {
    let subscriber;

    const subscribeOnDeleteQueueItem = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onDeleteQueueItem, { owner })
      );

      subscriber = await subscription.subscribe({
        next: (data) => {
          dispatch({
            type: ON_DELETE,
            payload: data.value.data.onDeleteQueueItem,
          });
        },
        error: () => {
          subscriber?.unsubscribe();
          subscribeOnDeleteQueueItem();
        },
      });
    };

    subscribeOnDeleteQueueItem();

    return () => subscriber?.unsubscribe();
  }, [serviceQueueID, owner]);

  return state;
};
