import { Field, Formik } from "formik";
import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useFetchBusinessData, useUser } from "../../hooks";
import { LoadingSpinner } from "../Loading";
import { TabContentSection } from "../TabsHeader";
import * as Yup from "yup";
import { updateBusiness } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { phoneRegExp } from "../../utils";

export const BusinessAccountSettings = ({ user }) => {
  const { addToast } = useToasts();
  const { business, isLoading } = useFetchBusinessData({ id: user.profile.id });
  const { updateUser } = useUser();
 
  const save = useCallback(
    async (businessname, businessphone, businessaddress, onCompleted) => {
      const input = {
        id: user.profile.id,
        name: businessname,
        phone: businessphone,
        address: businessaddress,
      };

      return await API.graphql(graphqlOperation(updateBusiness, { input }))
        .then((e) => {
          addToast("Business details saved successfully", {
            appearance: "info",
            autoDismiss: true,
            id: "business-details-saved-ok",
          });
          onCompleted();
        })
        .catch((err) => {
          console.log(err);
          addToast("Failed to save business details", {
            appearance: "error",
            autoDismiss: true,
            id: "business-details-saved-ko",
          });
          onCompleted();
        });
    },
    [user, addToast]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Formik
        initialValues={{
          businessname: business.name,
          businessphone: business.phone ? business.phone : "",
          businessaddress: business.address ? business.address : "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { businessname, businessphone, businessaddress } = values;
          save(businessname, businessphone, businessaddress, () => {
            setSubmitting(false)
            user.profile['name'] = businessname
            updateUser(user)
          }
          );
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => {
          return (
            <>
              <form name="updateServiceName" onSubmit={handleSubmit} className="box mx-1 is-shadowless has-border-grey">
                <TabContentSection title="Business Name">
                  <div className="field control">
                    <Field
                      className="input"
                      type="text"
                      name="businessname"
                      id="businessname"
                      placeholder="Business Name"
                    />
                    {touched.businessname && errors.businessname && (
                      <small className="help has-text-danger">
                        {errors.businessname}
                      </small>
                    )}
                  </div>
                </TabContentSection>

                <TabContentSection title="Business Phone">
                  <div className="field control">
                    <Field
                      className="input"
                      type="tel"
                      name="businessphone"
                      id="businessphone"
                      placeholder="Business Phone"
                    />
                    {touched.businessphone && errors.businessphone && (
                      <small className="help has-text-danger">
                        {errors.businessphone}
                      </small>
                    )}
                  </div>
                </TabContentSection>

                <TabContentSection title="Business Address">
                  <div className="field control">
                    <Field
                      className="input"
                      type="text"
                      name="businessaddress"
                      id="businessaddress"
                      placeholder="Business Address"
                    />
                    {touched.businessaddress && errors.businessaddress && (
                      <small className="help has-text-danger">
                        {errors.businessaddress}
                      </small>
                    )}
                  </div>
                </TabContentSection>

                <TabContentSection>
                  <div className="buttons is-right">
                    <button
                      className={`button is-primary isSubmitting ? "is-loading" : ""`}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span className="has-text-weight-bold">Save</span>
                    </button>
                  </div>
                </TabContentSection>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const validationSchema = Yup.object().shape({
  businessname: Yup.string().required("Name is Required"),
  businessphone: Yup.string().nullable().matches(phoneRegExp, "Phone number is not valid"),
  businessaddress: Yup.string().nullable()
});
