import React, { useState } from "react";
import { Formik, Field } from "formik";
import { MandatoryLabel, OptionalLabel } from "../Label";
import { DatePicker } from "@ui5/webcomponents-react/lib/DatePicker";
import { TimePicker } from "@ui5/webcomponents-react/lib/TimePicker";
import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import moment from "moment";
import * as Yup from "yup";
import { phoneRegExp } from "../../utils";

export const QueueItemForm = ({
  onFormSubmit,
  onClose,
  initialValues,
  title,
  buttonName,
}) => {
  const [expectedDate, setExpectedDate] = useState(initialValues.date);
  const [expectedTime, setExpectedTime] = useState(initialValues.time);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        let expected = expectedDate
          ? expectedDate
          : expectedTime
          ? moment().format("MMM DD, yyyy")
          : null;

        if (expectedTime) {
          expected =
            expected !== null ? expected + " - " + expectedTime : expectedTime;
        }

        onFormSubmit({ ...values, expected }, setSubmitting, resetForm);
      }}
    >
      {({ errors, touched, isSubmitting, handleSubmit, resetForm }) => {
        return (
          <>
            <div className="modal-card box">
              <div className="modal-card-head is-white">
                <p
                  className="modal-card-title has-text-centered is-size-4"
                  style={{ letterSpacing: "0.1em" }}
                >
                  {title}
                </p>
                <button
                  className="delete"
                  aria-label="close"
                  type="reset"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                ></button>
              </div>

              <section className="modal-card-body is-white">
                <div className="mb-2 has-text-left">
                  <form name="addQueueItem" onSubmit={handleSubmit}>
                    <div>
                      <MandatoryLabel label="Name" />
                      <div className="field ">
                        <div className="control is-expanded has-icons-left">
                          <Field
                            className="input is-medium"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                          />
                          <span className="icon is-small is-left">
                            <FaUser size={16} color={"darkgrey"} />
                          </span>

                          {touched.name && errors.name && (
                            <small className="help has-text-danger">
                              {errors.name}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="mt-5">
                        <OptionalLabel label="Contact" />

                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control has-icons-left">
                                <Field
                                  className="input is-medium"
                                  type="tel"
                                  name="phone"
                                  id="phone"
                                  placeholder="Optional Phone"
                                />
                                <span className="icon is-small is-left">
                                  <FaPhoneAlt size={16} color={"darkgrey"} />
                                </span>
                                {touched.phone && errors.phone && (
                                  <small className="help has-text-danger">
                                    {errors.phone}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="field">
                              <div className="control is-expanded has-icons-left">
                                <Field
                                  className="input is-medium"
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="Optional Email"
                                />
                                <span className="icon is-small is-left">
                                  <MdEmail size={24} color={"darkgrey"} />
                                </span>
                                {touched.email && errors.email && (
                                  <small className="help has-text-danger">
                                    {errors.email}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <OptionalLabel label="Expected" />

                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div>
                                <div className="control is-expanded">
                                  <DatePicker
                                    format-pattern="MMM dd, yyyy"
                                    value={expectedDate}
                                    onChange={(e) => {
                                      if (e.detail.valid) {
                                        setExpectedDate(e.target.value);
                                      }
                                    }}
                                    style={style}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="field">
                              <div>
                                <div className="control is-expanded">
                                  <TimePicker
                                    format-pattern="HH:mm"
                                    value={expectedTime}
                                    onChange={(e) => {
                                      if (e.detail.valid) {
                                        setExpectedTime(e.target.value);
                                      }
                                    }}
                                    style={style}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <OptionalLabel label="Note" />

                        <div className="field">
                          <div className="control is-expanded">
                            <Field
                              className="textarea is-medium"
                              component="textarea"
                              rows="2"
                              name="note"
                              id="note"
                              placeholder="Optional Note"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="field mt-6 is-grouped is-justify-content-center">
                        <div className="control">
                          <button
                            className="button is-primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <span className="has-text-weight-bold">
                              {buttonName}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const style = {
  width: "100%",
  "--sapField_BorderColor": "#dbdbdb",
  "--_ui5_input_wrapper_border_radius": "4px",
  "--sapFontSize": "1.25rem",
  "--sapField_TextColor": "--sapField_TextColor",
  "--_ui5_input_height": "2.5em",
  "--sapButton_Active_TextColor": "currentColor",
  "--sapButton_Active_Background": "none",
  boxShadow: "inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05)",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  phone: Yup.string()
    .nullable()
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string().nullable().email("Enter a Valid Email!"),
});
