import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import QboyImg from "../assets/img/qboy.png";

export const NotFoundPage = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container has-text-centered">
          <div className="columns is-centered">
            <div className="column is-7">
              <h1 className="title is-1">404</h1>
              <p className="subtitle is-3">Page not found</p>
              <figure className="image is-256x256 is-margin-auto">
                <img src={QboyImg} alt="queue"></img>
              </figure>
            </div>
          </div>
          <Link to="/" className="button is-primary">
            Back to homepage
          </Link>
        </div>
      </section>
    </Layout>
  );
};
