import { API, graphqlOperation } from "aws-amplify";
import { createBusiness, createServiceQueue } from "../../graphql/mutations";
import shortid from "shortid";

import * as PlanApi from "../PlanApi";

const businessByOwner = /* GraphQL */ `
  query BusinessByOwner($owner: String) {
    businessByOwner(owner: $owner, limit: 1) {
      items {
        id
        name
        address
        phone
        logoUrl
        code
        owner
        customerStripeId
        plan
        queues {
          items {
            code
          }
        }
      }
    }
  }
`;

export async function loadUser(cognitoUser) {
  const email = cognitoUser.attributes.email;
  const username = cognitoUser.username;

  const profile = await API.graphql(
    graphqlOperation(businessByOwner, { owner: username })
  ).then((response) => {
    if (response && response.data.businessByOwner.items.length > 0) {
      const {
        address,
        logoUrl,
        id,
        name,
        phone,
        customerStripeId,
        plan,
        queues,
      } = response.data.businessByOwner.items[0];
      return {
        address,
        logoUrl,
        id,
        name,
        phone,
        customerStripeId,
        plan: JSON.parse(plan),
        queues,
      };
    } else {
      return null;
    }
  });
  return { username, email, profile };
}

export async function createBusinessProfile(
  businessName,
  serviceName,
  username,
  email
) {
  const { customerStripeId, plan } = await PlanApi.createFreePlan(
    username,
    email
  );

  const business = await API.graphql(
    graphqlOperation(createBusiness, {
      input: {
        name: businessName,
        owner: username,
        customerStripeId,
        plan: JSON.stringify(plan),
        code: shortid.generate().toUpperCase(),
      },
    })
  ).then((response) => response.data.createBusiness);

  const service = await API.graphql(
    graphqlOperation(createServiceQueue, {
      input: {
        name: serviceName,
        serviceQueueBusinessId: business.id,
        code: shortid.generate().toUpperCase(),
      },
    })
  ).then((response) => response.data.createServiceQueue);

  const businessProfile = {
    ...business,
    queues: {
      items: [
        {
          code: service.code,
        },
      ],
    },
  };

  businessProfile['plan'] = plan;

  return businessProfile;
}
