import React from 'react'
import QboyImg from '../assets/img/qboy.png'

export const IntroSection = () => {
  return (
    <section id="intro" className="hero is-bold is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered reverse-row-order">
            <div className="column is-12-mobile is-6  ">
              <figure className="image is4by3 mt-2">
                <img src={QboyImg} alt="queue"></img>
              </figure>
            </div>

            <div className="column is-12-mobile is-6">
              <div className="section has-text-centered">
                <h1 className="title has-text-weight-semibold is-size-1 is-size-3-mobile">
                  Happy Queuing
                </h1>
                <h2 className="is-size-5 is-size-6-mobile">
                  Relax and manage your wait with QBoy, the next Queue Management Tool.
                </h2>

                <a className="button is-rounded is-medium is-primary mt-6" href="#features">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
