import React from 'react'
import { Link } from 'react-router-dom'

export const GetStartedSection = () => {
  return (
    <section id="getstarted" className="section section--gradient has-background-info">
      <div className="container section">
        <p className="title has-text-centered mb-3 is-size-2 is-size-3-mobile has-text-white">
          Manage your waiting list with ease
        </p>

        <div className="columns is-vcentered  is-centered">
          <div className="column">
            <p className="has-text-centered m-5 pt-3 is-medium">
              <Link className="button is-outlined has-font-weight-bold is-medium" to="/login">
                Get started
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
