import React, { useState } from "react";

import { Formik, Field } from "formik";

import {
  signInValidationSchema,
  signUpValidationSchema,
  confirmValidationSchema,
} from "./validationSchema";

import { Link } from "react-router-dom";
import { useUser } from "../../hooks";

async function signInHandler(
  { username, password },
  loginHandler,
  updateFormGlobalError
) {
  try {
    updateFormGlobalError("");
    await loginHandler(username, password);
  } catch (err) {
    updateFormGlobalError(err.message);
    throw err;
  }
}

async function signUpHandler(
  { username, password, email },
  signupHandler,
  updateFormType,
  updateSignUpUsername,
  updateSignUpPassword,
  updateFormGlobalError
) {
  try {
    updateFormGlobalError("");
    await signupHandler(username, password, email);
    updateFormType("confirmSignUp");
    updateSignUpUsername(username);
    updateSignUpPassword(password);
  } catch (err) {
    updateFormGlobalError(err.message);
    throw err;
  }
}

async function confirmSignUpHandler(
  confirmationCode,
  username,
  password,
  confirmSignup,
  updateFormGlobalError
) {
  try {
    updateFormGlobalError("");
    await confirmSignup(username, password, confirmationCode);
  } catch (err) {
    updateFormGlobalError(err.message);
    throw err;
  }
}

export default function AuthForm() {
  const [formType, updateFormType] = useState("signIn");
  const [formGlobalError, updateFormGlobalError] = useState("");
  const [signUpUsername, updateSignUpUsername] = useState("");
  const [signUpPassword, updateSignUpPassword] = useState("");
  const { login, signup, confirmSignup } = useUser();

  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            signUp={(values) =>
              signUpHandler(
                values,
                signup,
                updateFormType,
                updateSignUpUsername,
                updateSignUpPassword,
                updateFormGlobalError
              )
            }
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            confirmSignUp={(code) =>
              confirmSignUpHandler(
                code,
                signUpUsername,
                signUpPassword,
                confirmSignup,
                updateFormGlobalError
              )
            }
          />
        );
      case "signIn":
        return (
          <SignIn
            signIn={(values) =>
              signInHandler(values, login, updateFormGlobalError)
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <div>
      {formGlobalError !== "" && (
        <div className="notification is-danger is-light p-4 help" role="alert">
          {formGlobalError}
        </div>
      )}
      <div>{renderForm()}</div>
      {formType === "signUp" && (
        <div className="help" style={styles.footer}>
          <p>
            By continuing, you agree to our{" "}
            <Link to="/terms">Terms of Service</Link> and{" "}
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <hr style={styles.hr}/>
          Already have an account?{" "}
          <span
            style={styles.anchor}
            onClick={() => {
              updateFormGlobalError("");
              updateFormType("signIn");
            }}
          >
            Sign In
          </span>
        </div>
      )}
      {formType === "signIn" && (
        <div className="help" style={styles.footer}>
          <p>
            By continuing, you agree to our{" "}
            <Link to="/terms">Terms of Service</Link> and{" "}
            <Link to="/privacy">Privacy Policy</Link>
          </p>          
          <hr style={styles.hr}/>
          Need an account?{" "}
          <span
            style={styles.anchor}
            onClick={() => {
              updateFormGlobalError("");
              updateFormType("signUp");
            }}
          >
            Sign Up
          </span>
        </div>
      )}
    </div>
  );
}

function SignUp(props) {
  return (
    <>
      <div className="has-text-centered mb-5">
        <h3 className="title has-text-black">Sign Up</h3>
        <p className="subtitle has-text-grey pt-1 is-size-6">
          Happy queuing with QBoy
        </p>
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          confirmpassword: "",
          email: "",
        }}
        validationSchema={signUpValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.signUp(values).catch(() => {
            setSubmitting(false);
          });
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <form name="signup" onSubmit={handleSubmit}>
            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  name="username"
                  type="text"
                  placeholder="Username"
                />
                {touched.username && errors.username && (
                  <small className="help has-text-danger">
                    {errors.username}
                  </small>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                {touched.email && errors.email && (
                  <small className="help has-text-danger">{errors.email}</small>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  id="password"
                  key="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
                {touched.password && errors.password && (
                  <small className="help has-text-danger">
                    {errors.password}
                  </small>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  id="confirmpassword"
                  key="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  placeholder="Confirm Password"
                />
                {touched.confirmpassword && errors.confirmpassword && (
                  <small className="help has-text-danger">
                    {errors.confirmpassword}
                  </small>
                )}
              </div>
            </div>

            <div className="mb-5">
              <button
                className={`button is-block is-info is-medium is-fullwidth ${
                  isSubmitting ? "is-loading" : ""
                }`}
                type="submit"
                disabled={isSubmitting}
              >
                <span className="heading is-size-6 has-text-weight-semibold ">
                  SIGN UP
                </span>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

function SignIn(props) {
  return (
    <>
      <div className="has-text-centered mb-5">
        <h3 className="title has-text-black">Sign In</h3>
        <p className="subtitle has-text-grey pt-1 is-size-6">
          Happy queuing with QBoy
        </p>
      </div>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={signInValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.signIn(values).catch(() => {
            setSubmitting(false);
          });
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <form name="login" onSubmit={handleSubmit}>
            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  name="username"
                  type="text"
                  placeholder="Username"
                />
                {touched.username && errors.username && (
                  <small className="help has-text-danger">
                    {errors.username}
                  </small>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  id="password"
                  key="password"
                  name="password"
                  type="password"
                  placeholder="Enter password"
                />
                {touched.password && errors.password && (
                  <small className="help has-text-danger">
                    {errors.password}
                  </small>
                )}
              </div>
            </div>

            {/*
              <div className="py-3 has-text-right">
                <a href="/#" className="is-size-6">
                  Forget Password
                </a>
              </div>
              */}

            <div className="mb-5">
              <button
                className={`button is-block is-info is-medium is-fullwidth ${
                  isSubmitting ? "is-loading" : ""
                }`}
                type="submit"
                disabled={isSubmitting}
              >
                <span className="heading is-size-6 has-text-weight-semibold ">
                  SIGN IN
                </span>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

function ConfirmSignUp(props) {
  return (
    <>
      <div className="has-text-centered mb-5">
        <h3 className="title has-text-black">
          Enter the verification code we sent to your email
        </h3>
      </div>
      <Formik
        initialValues={{ confirmationcode: "" }}
        validationSchema={confirmValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { confirmationcode } = values;

          props.confirmSignUp(confirmationcode).catch(() => {
            setSubmitting(false);
          });
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <form name="confirm" onSubmit={handleSubmit}>
            <div className="field">
              <div className="control">
                <Field
                  className="input is-medium"
                  id="confirmationcode"
                  key="confirmationcode"
                  name="confirmationcode"
                  type="password"
                  placeholder="Enter code"
                />
                {touched.confirmationcode && errors.confirmationcode && (
                  <small className="help has-text-danger">
                    {errors.confirmationcode}
                  </small>
                )}
              </div>
            </div>

            <div className="mb-5">
              <button
                className={`button is-block is-info is-medium is-fullwidth ${
                  isSubmitting ? "is-loading" : ""
                }`}
                type="submit"
                disabled={isSubmitting}
              >
                <span className="heading is-size-6 has-text-weight-semibold ">
                  VERIFY
                </span>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

const styles = {
  footer: {
    fontWeight: "600",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
  },
  anchor: {
    color: "#006bfc",
    cursor: "pointer",
  },

  hr: {
    margin: "0.8rem"
  }
};
