import React from "react";
import Loader from "react-loader-spinner";

export const LoadingSpinner = (props) => {
  const {
    loaderColor = "#098c8c",
    loaderType = "TailSpin",
  } = props;

  return (
    <Loader
      style={styles.spinner}
      type={loaderType}
      height="40"
      width="40"
      color={loaderColor}
    />
  );
};

const styles = {
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2em"
  },
};
