import React from "react";
import { Formik, Field } from "formik";
import { BareNavBar } from "../NavBar";
import { validationSchema } from "./validationSchema";
import { useUser } from "../../hooks";
import { MandatoryLabel } from "../Label";

export const CreateProfileModal = (props) => {
  const { show } = props;

  const { user, createBusinessProfile } = useUser();

  return (
    <div className={`modal ${show ? `is-active` : ""}`}>
      <BareNavBar />

      <div className="modal-background"></div>

      <div className="modal-card">
        <div className="modal-card-head is-justify-content-center">
          <div
            className="is-size-4 has-text-centered"
            style={{ letterSpacing: "0.1em" }}
          >
            <div className="mt-6">Create your Service</div>
          </div>
        </div>

        <section className="modal-card-body">
          <div className="mb-2 has-text-left">
            <Formik
              initialValues={{
                businessName: "",
                serviceName: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const { businessName, serviceName } = values;
                createBusinessProfile(businessName, serviceName, user);
              }}
            >
              {({ errors, touched, isSubmitting, handleSubmit }) => (
                <form name="profile" onSubmit={handleSubmit}>
                  <MandatoryLabel label="Business Name" />
                  <div className="field control">
                    <Field
                      className="input is-medium"
                      type="text"
                      name="businessName"
                      id="name"
                      placeholder="Business Name"
                    />
                    {touched.businessName && errors.businessName && (
                      <small className="help has-text-danger">
                        {errors.businessName}
                      </small>
                    )}
                  </div>

                  <MandatoryLabel label="Service Name" className="mt-5" />
                  <div className="field control">
                    <Field
                      className="input is-medium"
                      type="text"
                      name="serviceName"
                      id="name"
                      placeholder="Service Name"
                    />
                    <small className="help">
                      e.g. Script In, Takeaway, Car Servicing...
                    </small>
                    {touched.serviceName && errors.serviceName && (
                      <small className="help has-text-danger">
                        {errors.serviceName}
                      </small>
                    )}
                  </div>

                  <div className="field mt-6 is-grouped is-justify-content-center">
                    <div className="control">
                      <button
                        className={`button is-primary ${
                          isSubmitting ? "is-loading" : ""
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <span className="has-text-weight-bold">Save</span>
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </div>
  );
};
