import React, { useCallback, useRef, useState } from "react";

import { FaMinusCircle } from "react-icons/fa";
import { MessageBox } from "@ui5/webcomponents-react/lib/MessageBox";
import { MessageBoxTypes } from "@ui5/webcomponents-react/lib/MessageBoxTypes";
import { MessageBoxActions } from "@ui5/webcomponents-react/lib/MessageBoxActions";
import { deleteQueueItem } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export const DeleteQueuerButton = ({ id, name, onDeletion }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const deleteQueuer = useCallback(() => {
    const input = {
      id,
    };

    API.graphql(graphqlOperation(deleteQueueItem, { input }));
  }, [id]);

  return (
    <>
      <ConfirmationBox
        open={isOpen}
        onClose={(event) => {
          setIsOpen(false);
          if (event.detail.action === "Delete") {
            deleteQueuer();
            onDeletion(true);
          }
        }}
        title="Delete"
        message={`Delete ${name}?`}
        actionTitle="Delete"
      />

      <button
        className="button is-danger is-outlined is-small"
        ref={buttonRef}
        onClick={() => {
          setIsOpen(true);
          buttonRef.current.blur();
        }}
      >
        <span className="icon is-small">
          <FaMinusCircle size={16} />
        </span>
      </button>
    </>
  );
};

const ConfirmationBox = ({ open, onClose, title, message, actionTitle }) => {
  return (
    <MessageBox
      title={title}
      type={MessageBoxTypes.WARNING}
      open={open}
      onClose={onClose}
      actions={[actionTitle, MessageBoxActions.CANCEL]}
    >
      {message}
    </MessageBox>
  );
};
