import React from "react";
import { Layout } from "./Layout";

export const ServiceLayout = (props) => {
  const { activeNavIndex, title, subtitle } = props;

  return (
    <Layout>
      <section className="is-min-half-height">

        <div className="section">
          <h1 className="has-text-centered">
            <span className="title has-text-weight-semibold is-size-3 ">
              {title}      
            </span>


            {subtitle && (
              <p className="pt-2 heading has-text-grey is-size-6">
                {subtitle}
              </p>
            )}
          </h1>
        </div>

        <div className="columns is-centered">
          <main className="column">{props.children}</main>
        </div>
      </section>
    </Layout>
  );
};
