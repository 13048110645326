import React, { useCallback } from "react";
import { createQueueItem } from "../../graphql/mutations";
import { STATUS_QUEUED } from "../../utils/status";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";
import shortid from "shortid";
import { QueueItemForm } from "./QueueItemForm";

export const AddQueuerModal = (props) => {
  const { show, queueId, onClose, onAdded, onError } = props;

  const save = useCallback(
    async (name, note, email, phone, expectedDate, onSaved, onError) => {
      const input = {
        queueItemQueueId: queueId,
        queuer: name,
        status: STATUS_QUEUED,
        note,
        email,
        phone,
        expectedDate,
        queuedOn: new Date().toISOString(),
        code: shortid.generate().toUpperCase(),
      };

      return await API.graphql(graphqlOperation(createQueueItem, { input }))
        .then(() => {
          onSaved();
        })
        .catch((err) => {
          onError(err);
        });
    },
    [queueId]
  );

  const AddForm = () => {
    const onSubmit = (values, setSubmitting, resetForm) => {
      const { name, phone, email, note, expected } = values;
      save(
        name,
        note,
        email,
        phone,
        expected,
        () => {
          setSubmitting(false);
          resetForm();
          onAdded(name);
        },
        (error) => {
          console.log({ error });
          setSubmitting(false);
          onError(name);
        }
      );
    };

    const initialValues = {
      name: "",
      note: "",
      phone: "",
      email: "",
      date: null,
      time: "",
    };

    return (
      <QueueItemForm
        title="Add"
        buttonName="Add"
        onFormSubmit={(values, setSubmitting, resetForm) =>
          onSubmit(values, setSubmitting, resetForm)
        }
        onClose={onClose}
        initialValues={initialValues}
      />
    );
  };

  return (
    <div className={`modal ${show ? `is-active` : ""}`}>
      <div className="modal-background"></div>

      <AddForm />
    </div>
  );
};

const Upgrade = ({ onClose }) => {
  return (
    <>
      <div className="modal-card box">
        <div className="modal-card-head is-white">
          <p className="modal-card-title"></p>
          <button
            className="delete"
            aria-label="close"
            type="reset"
            onClick={() => {
              onClose();
            }}
          ></button>
        </div>

        <section className="modal-card-body is-white">
          <div className="mb-2 has-text-centered">
            <div className="mt-5">
              <div className="title is-size-4">
                You have reached your total number of waitlisted allowed for the
                day.
              </div>
              <div className="pt-5">
                Upgrade for unlimited number of waitlisted.
              </div>
            </div>

            <div className="field mt-6 is-grouped is-justify-content-center">
              <div className="control">
                <Link className="button is-primary" to="/upgrade">
                  <span className="has-text-weight-bold">Upgrade</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
