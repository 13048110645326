import React from "react";
import { useToasts } from "react-toast-notifications";
import { TabContentSection } from "../TabsHeader";

export const WaitListFields = ({ sid }) => {
  return (
    <>
      <div className="box mx-1 is-shadowless has-border-grey">
        <div className="p-1 has-text-grey">
          <p className="title is-size-5 pb-0 mb-2">Waitlist Input Fields</p>
          <p className="is-size-6">Configure what input fields to be shown in the waitlist.</p>
        </div>

        <TabContentSection></TabContentSection>
      </div>
    </>
  );
};
