import React from "react";
import { useFetchQueueItemsData } from "../../hooks";
import { QueueTable } from "./QueueTable";
import { LoadingSpinner } from "../Loading";

export const QueueTableContainer = ({ queueId, user, onEdit }) => {
  const { queueItems, isLoading } = useFetchQueueItemsData({
    serviceQueueID: queueId,
    owner: user.username,
  });

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <>
          <QueueTable queueItems={queueItems} 
            onEdit={queueItem => onEdit(queueItem)}/>

          <div className="has-border-top-lightgrey">
            <QueueCounter queueItems={queueItems} />
          </div>        
        </>
      )}
    </>
  );
};

const QueueCounter = ({ queueItems }) => {
  const waiting = queueItems.filter((q) => q.status === 1).length;
  const ready = queueItems.filter((q) => q.status === 2).length;

  return (
    <div className="p-2 is-size-7 heading">
      {waiting} waiting, {ready} ready
    </div>
  );
};
