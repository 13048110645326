import React from "react";
import { useToasts } from "react-toast-notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const WaitListPublicLink = ({ sid }) => {
    const publicWaitingURL = "https://online.qboy.com.au/sid/" + sid;
    const { addToast } = useToasts();
  
    return (
      <>
        <div className="box mx-1 is-shadowless has-border-grey">
          <div className="p-1 has-text-grey">
            <p className="title is-size-5 pb-0 mb-2">Customer Waitlist Link</p>
            <p className="is-size-6">Public weblink for customers to access the waitlist online.</p>
          </div>

          <section className="my-5">

            <div className="field has-addons">
              <input
                className="input"
                type="text"
                name="sid"
                id="sid"
                value={publicWaitingURL}
                disabled={true}
                readOnly={true}
              />
              <div className="control">
                <a
                  href={publicWaitingURL}
                  className="button is-info is-outlined"
                  title="Public waiting link"
                  rel="noreferrer"
                  target="_blank"
                >
                  OPEN
                </a>
              </div>
  
              <div className="control">
                <CopyToClipboard
                  text={publicWaitingURL}
                  onCopy={() =>
                    addToast("Link copied to clipboard", {
                      appearance: "info",
                      autoDismiss: true,
                      id: "public-waiting-url-clipboard",
                    })
                  }
                >
                  <button className="button is-info is-outlined">COPY</button>
                </CopyToClipboard>
              </div>
            </div>
            </section>
        </div>
      </>
    );
}
