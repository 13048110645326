import React from "react";
import Footer from "../components/Footer";
import { BareNavBar } from "../components/NavBar";

export const UnexpectedErrorPage = () => {
  return (
    <div id="layout-wrapper">
      <BareNavBar />

      <div id="content-wrapper">
        <section className="section">
          <div className="container has-text-centered">
            <div className="columns is-centered">
              <div className="column is-7">
                <h1 className="title is-1">500</h1>
                <p className="subtitle is-3">Unexpected Error :(</p>
              </div>
            </div>
            <a href="/"  className="button is-primary mt-6" title="qboy.com.au">
              Back to homepage
            </a>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

