import React, { useCallback } from "react";

const inactiveModalState = {
  name: null,
  data: null,
};

export const ModalContext = React.createContext(null);

export const ModalContextProvider = ({ children }) => {
  const [modalState, setModalState] = React.useState(inactiveModalState);

  const closeModal = useCallback(() => setModalState(inactiveModalState), []);
  const openModal = useCallback(({ name, data }) =>
    setModalState({ name, data }), []);

  const values = React.useMemo(() => ({ modalState, openModal, closeModal }), [
    modalState,
    openModal,
    closeModal
  ]);

  return (
    <ModalContext.Provider value={values}>
      {children}
    </ModalContext.Provider>
  );
};
