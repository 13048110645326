import React from "react";
import { useToasts } from "react-toast-notifications";
import { UpdateQueuerModal } from "./UpdateQueuerModal";
import { useModalContext } from "../../hooks";

export const UpdateQueuerModalController = () => {
  const { modalState, closeModal } = useModalContext();
  const showModal = modalState !== null && modalState.name === 'EDIT_QUEUE_ITEM'

  const { addToast } = useToasts();

  return (
    <>
      {showModal && (
        <UpdateQueuerModal
          queuerData={modalState.data}
          show={showModal}
          onClose={() => closeModal()}
          onUpdated={(name) => {
            closeModal();
            addToast(name + " is updated", {
              appearance: "info",
              autoDismiss: true,
            });            
          }}
          onError={(name)=> {
            closeModal();
            addToast(`Update failed. ${name} not found.`, {
              appearance: "error",
              autoDismiss: true,
            });
          }}          
        />
      )}
    </>
  );
};
