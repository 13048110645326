import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { API, graphqlOperation } from "aws-amplify";
import { updateServiceQueue } from "../../graphql/mutations";

import { useFetchServiceQueueData } from "../../hooks";
import { TabContentSection } from "../TabsHeader";
import { LoadingSpinner } from "../Loading";

export const ServiceInfo = ({ sid }) => {
    const { addToast } = useToasts();
    const { serviceQueue, isLoading } = useFetchServiceQueueData({ sid });
  
    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Name is Required"),
    });
  
    const save = useCallback(
      async (name, onCompleted) => {
        const input = {
          id: serviceQueue.id,
          name,
        };
  
        return await API.graphql(graphqlOperation(updateServiceQueue, { input }))
          .then(() => {
            addToast("Service name updated successfully", {
              appearance: "info",
              autoDismiss: true,
              id: "service-name-updated-ok",
            });
            onCompleted();
          })
          .catch((err) => {
            console.err(err);
            addToast("Failed to update service name", {
              appearance: "error",
              autoDismiss: true,
              id: "service-name-updated-ko",
            });
            onCompleted();
          });
      },
      [serviceQueue, addToast]
    );
  
    if (isLoading) {
      return <LoadingSpinner />;
    }
    return (
      <>
        <div className="box mx-1 is-shadowless has-border-grey">
          <TabContentSection title="SID">
            <div className="field control">
              <input
                className="input"
                type="text"
                name="sid"
                id="sid"
                value={serviceQueue.code}
                disabled={true}
                readOnly={true}
              />
            </div>
          </TabContentSection>
  
          <TabContentSection title="Service Name">
            <Formik
              initialValues={{
                name: serviceQueue.name,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const { name } = values;
                save(name, () => setSubmitting(false));
              }}
            >
              {({ errors, touched, isSubmitting, handleSubmit }) => {
                return (
                  <>
                    <form name="updateServiceName" onSubmit={handleSubmit}>
                      <div className="field has-addons mb-0">
                        <Field
                          className="input"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Service Name"
                        />
  
                        <div className="control">
                          <button
                            className={`button is-primary isSubmitting ? "is-loading" : ""`}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            SAVE
                          </button>
                        </div>
                      </div>
                      {touched.name && errors.name && (
                        <small className="help has-text-danger">
                          {errors.name}
                        </small>
                      )}
                    </form>
                  </>
                );
              }}
            </Formik>
          </TabContentSection>
        </div>
      </>
    );
  };