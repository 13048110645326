import * as Yup from 'yup'

export const signInValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is Required'),
  password: Yup.string().required('Password is Required'),
})

export const signUpValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is Required'),
  email: Yup.string()
    .email('Enter a Valid Email!')
    .required('Email is Required!'),
  password: Yup.string()
    .required('Password is Required')
    .min(6, 'Password must be at least 6 characters'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

export const confirmValidationSchema = Yup.object().shape({
  confirmationcode: Yup.string().required('Confirmation code is Required'),
})
