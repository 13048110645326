import React from "react";
import { useToasts } from "react-toast-notifications";
import { useModalContext } from "../../hooks";
import { AddQueuerModal } from "./AddQueuerModal";

export const AddQueuerModalController = ({ onAdded }) => {
  const { modalState, closeModal } = useModalContext();
  const showModal = modalState !== null && modalState.name === 'ADD_QUEUE_ITEM'

  const { addToast } = useToasts();

  return (
    <>
      {showModal && (
        <AddQueuerModal
          queueId={modalState.data.queueId}
          counter={0}
          user={modalState.data.user}
          show={showModal}
          onClose={() => closeModal()}
          onAdded={(name) => {
            onAdded();
            closeModal();
            addToast(name + " is added", {
              appearance: "info",
              autoDismiss: true,
            });
          }}
          onError={(name)=> {
            closeModal();
            addToast("Failed to add " + name, {
              appearance: "error",
              autoDismiss: true,
            });
          }}
        />
      )}     
    </>
  );
};
