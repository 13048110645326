import React from "react";
import { Layout } from "../components/Layout/Layout";

export const TermsPage = () => {
  return (
    <Layout>
      <div>
        <section className="section section--gradient">
          <div className="container">
            <br />
            <p className="title mb-1 has-text-grey">
              Terms & Conditions of Use
            </p>
          </div>

          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">Definition</p>

              <p className="is-size-6">
                You (Your) or USER– means the user of the website or the
                customers or clients of the businesses that signed up with us
                and created a service for the benefit of their trades.
              </p>

              <br />
              <p className="is-size-6">
                The businesses or business – entities like the pharmacies,
                restaurants, event organizers and other establishments that
                availed or thinking to avail of our products and services.
              </p>

              <br />
              <p className="is-size-6">
                We (Our/Us) – may pertain to the domain{" "}
                <a href="https://www.www.qboy.com.au">www.qboy.com.au</a>{" "}
                provided by Locoomago Pty Ltd or the team of the company or the
                company itself.
              </p>

              <br />
              <p className="is-size-6">
                Site - the website{" "}
                <a href="https://www.www.qboy.com.au">www.qboy.com.au</a>
              </p>

              <br />
              <p className="is-size-6">
                Service - we provide advertising services through a software as
                a service by means of a queue management tool.
              </p>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">
                1. Acceptance of the Terms and Conditions
              </p>
              <ul>
                <li>
                  You as a user or anyone representing a business accepts this
                  terms and conditions the moment you or a representative of a
                  business starts viewing our website or in any chance avail of
                  our services.
                </li>
                <li>
                  The business or its representative agrees that they will take
                  full responsibility on the content that they will be putting
                  online in regards to the services we provided to them as a
                  24/7 online tool in improving their business outcome. The
                  business will be liable to the accuracy of its content and
                  take full responsibility and risks in doing so. Our company or
                  its directors, affiliates, staff or shareholders are NOT
                  liable to any harm, losses, damages, expenses, moral,
                  physical, emotional or any type of harm, loss or burden you
                  may incur arising directly, indirectly, incidental,
                  consequential or however caused from using our service or
                  content or anything provided for or in relation to the company
                  or this website.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">2. Service</p>
              <ul>
                <li>
                  The service is to provide a queue management system and/or
                  advertising services.
                </li>
                <li>
                  The service aims to improve the KPI of the business through
                  managing the waiting time in the premises and implement a
                  health social distancing in this time of COVID 19 pandemic
                  crisis.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">3. Changes</p>
              <ul>
                <li>
                  Changes to the content of our website and its terms and
                  conditions of use may happen at any given time. Therefore, it
                  is your responsibility to constantly check our site whenever
                  you use it. It is understood that you accepted our terms and
                  conditions before you use our site and services each and every
                  time. If by any reason, you do not agree at any time, then you
                  are not allowed to use it.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">4. Refund Policy</p>
              <ul>
                <li>We have a Non-Refundable fee policy.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">
                5. Copyright and Intellectual Property
              </p>
              <ul>
                <li>
                  The service and all of the related products of the website or
                  the company mentioned above are subject to copyright. The
                  material on the site is protected by copyright under the laws
                  of Australia and through international treaties. Unless
                  otherwise indicated, all rights (including copyright) in the
                  Services and compilation of the site (including but not
                  limited to text, graphics, logos, button icons, video images,
                  audio clips, Application, code, scripts, design elements and
                  interactive features) or the Services are owned or controlled
                  for these purposes, and are reserved by Locoomago PTY LTD or
                  its contributors.
                </li>
                <li>
                  All trademarks, service marks and trade names are owned,
                  registered and/or licensed by Locoomago PTY LTD, who grants to
                  you a worldwide, non-exclusive, royalty-free, revocable
                  license while you use our site or services.
                </li>
                <li>
                  Locoomago PTY LTD does not grant you any other rights
                  whatsoever in relation to the site or the Services. All other
                  rights are expressly reserved by Locoomago PTY LTD.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">6. Liability/Indemnity</p>
              <ul>
                <li>
                  Locoomago PTY LTD will NOT be liable to you for any losses,
                  damages, expenses, moral, physical, emotional or any type of
                  harm, loss or burden you may incur arising directly,
                  indirectly, incidental, consequential or however caused from
                  using our site or service including from any advice given or
                  not given or from any content or anything provided for or in
                  relation to the company or this website.
                </li>
                <li>
                  Our site and services are both provided on “as is” and “as
                  available” basis and will NOT be liable for any inaccuracy,
                  error or failure that may be present. Therefore, you agree to
                  verify and take full responsibility as to its accuracy and
                  release our site and company together with its contributors of
                  any liabilities.
                </li>
                <li>
                  You agree to indemnify Locoomago Pty Ltd or the website, its
                  directors, affiliates, employees, contributors, agents,
                  licensors and third-party providers from and against:
                </li>

                <ol>
                  <li>
                    All actions, suits, demands, costs, expenses, loss, claims,
                    and damage (including legal fees on dull indemnity basis)
                    incurred, suffered or arising out of or in connection with
                    using our content or advice or services.
                  </li>
                  <li>
                    Any direct or indirect consequences of you accessing, using
                    or by reading the content of our website or availing our
                    services or attempts to do so; and/or
                  </li>
                  <li>Any breach of the Terms.</li>
                </ol>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">7. Warranty/Guarantee</p>
              <ul>
                <li>
                  We do not provide any warranties or guarantees in the success
                  of your journey in becoming a pharmacist in Australia.
                </li>
                <li>
                  At the same time that we do NOT give warranty or any form of
                  assurance or representations as to the accuracy or
                  completeness of the content of our site or our services.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">8. Privacy</p>
              <ul>
                <li>
                  We take your privacy seriously and any information provided
                  through your use of the site or services are subject to our
                  Privacy Policy, which is available on the website.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">9. Disclaimer</p>
              <ul>
                <li>Using our site or services will be at your own risk.</li>
                <li>
                  Information shown online may not be accurate at the time due
                  to internet log time or connection problem in the business
                  side.
                </li>
                <li>
                  All terms, guarantees, warranties, representations, or
                  conditions which are not expressly stated in the Terms are
                  excluded to the extent permitted by law.
                </li>
                <li>
                  We do not sell any hardware. Hence, most of our services would
                  rely on a good internet connection.
                </li>
                <li>
                  We reserve the right to delete any data entered by the
                  business in any instance that it violates any human rights and
                  privacy policy according to the law.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">10. Governing law</p>
              <ul>
                <li>
                  The Terms and Conditions in this document shall be governed,
                  interpreted and construed by, under and pursuant to the laws
                  of the state of Victoria, Australia.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p className="is-size-4 title">11. Severance</p>
              <ul>
                <li>
                  If any part of these Terms is found to be void or
                  unenforceable, that part shall be severed, and the rest of the
                  Terms shall remain in force.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section section--gradient">
          <div className="container">
            <div>
              <br />
              <p>
                If you have any concerns regarding this terms and conditions of
                use, please send an email to{" "}
                <a
                  href="mailto:qboy.au@gmail.com"
                  target="_blank"
                  title="Email us"
                  rel="noreferrer"
                >
                  <span>qboy.au@gmail.com</span>
                </a>
              </p>
            </div>
          </div>
        </section>

        <section className="section section--gradient"></section>
      </div>
    </Layout>
  );
};
