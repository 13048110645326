import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import * as AuthApi from "../api/AuthApi";
import * as UserApi from "../api/UserApi";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState({ isLoading: true });
  const history = useHistory();

  React.useEffect(() => {
    AuthApi.currentAuthenticatedUser(
      (cognitoUser) => loadUser(cognitoUser),
      () => setUser(null)
    );
  }, []);

  const logout = useCallback(() => {
    AuthApi.signOut(() => setUser(null));
  }, []);

  const loadUser = async (cognitoUser) => {
    const loaded = await UserApi.loadUser(cognitoUser);
    setUser(loaded);
  };

  const login = useCallback(async (username, password) => {
    return AuthApi.signIn(
      username,
      password,
      (cognitoUser) => loadUser(cognitoUser),
      (err) => {
        throw err;
      }
    );
  }, []);

  const signup = useCallback(async (username, password, email) => {
    return await AuthApi.signUp(username, password, email, (err) => {
      throw err;
    });
  }, []);

  const confirmSignup = useCallback(
    async (username, password, confirmationCode) => {
      return AuthApi.confirmSignUp(
        username,
        password,
        confirmationCode,
        (cognitoUser) => loadUser(cognitoUser),
        (error) => {
          throw error;
        }
      );
    },
    []
  );

  const createBusinessProfile = useCallback(async (businessName, serviceName, user) => {
    const profile = await UserApi.createBusinessProfile(
      businessName,
      serviceName,
      user.username,
      user.email
    );
    setUser({
      ...user,
      profile,
    });
  }, []);

  const updateUser = useCallback((user) => {
    setUser({...user});
  }, []);

  const ensureUserIsLoggedIn = useCallback(async () => {
    AuthApi.isUserNotLoggedIn(
      () => {
        setUser(null);
        history?.push("/login");        
      }
    )   
  }, [history]);

  const values = React.useMemo(
    () => ({
      user,
      login,
      logout,
      signup,
      confirmSignup,
      createBusinessProfile,
      ensureUserIsLoggedIn,
      updateUser
    }),
    [user, login, logout, signup, confirmSignup, createBusinessProfile, ensureUserIsLoggedIn, updateUser]
  );

  // Finally, return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
