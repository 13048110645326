import React, { useState } from "react";

import { StatusUpdateButton } from "../StatusUpdateButton";
import { FaEdit, FaSearch } from "react-icons/fa";
import moment from "moment";

import "./styles.scss";
import { DeleteQueuerButton } from "./DeleteQueuerButton";
import Loader from "react-loader-spinner";
import { ActionButton } from "./ActionButton";

export const QueueTable = ({ queueItems, onEdit }) => {
  const [filtered, setFiltered] = useState("");

  return (
    <>
      <div className="table-container has-text-centered">
        <div className="control has-icons-left mt-4">
          <input
            className="input is-shadowless is-borderless has-border-bottom-lightgrey mb-5"
            type="text"
            id="search"
            onKeyUp={(e) => {
              e.preventDefault();
              setFiltered(e.target.value);
            }}
            placeholder="Filter by name or status"
          />
          <span className="icon is-small is-left">
            <FaSearch color={"darkgrey"} />
          </span>
        </div>

        <table className="table is-hoverable is-fullwidth queuetable">
          <thead>
            <tr>
              <th
                className="heading is-tablecell"
                style={tableStyles.statusWidth}
              >
                Status
              </th>

              <th className="heading is-tablecell">Name</th>

              <th
                className="heading is-tablecell"
                style={tableStyles.expectedWidth}
              >
                Expected
              </th>
              <th
                className="heading is-tablecell"
                style={tableStyles.actionWidth}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {queueItems
              .filter((i) => {
                if (filtered) {
                  const filteredStatus =
                    filtered.toLowerCase() === "waiting"
                      ? 1
                      : filtered.toLowerCase() === "ready"
                      ? 2
                      : 0;

                  return (
                    i.queuer.toLowerCase().indexOf(filtered.toLowerCase()) >
                      -1 || i.status === filteredStatus
                  );
                } else {
                  return i;
                }
              })
              .map((item) => (
                <QueuerRow key={item.id} item={item} onEdit={item => onEdit(item)}/>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const QueuerRow = ({ item, onEdit }) => {
  let statusLabel, statusTagStyle, nextStatus;
  const [isDeletion, setIsDeletion] = useState(false);

  if (item.status === 1) {
    statusLabel = "WAITING";
    statusTagStyle = "is-warning";
    nextStatus = 2;
  } else {
    statusLabel = "READY";
    statusTagStyle = "is-success";
    nextStatus = 3;
  }

  return (
    <tr>
      <td className="has-text-centered">
        <span className={`tag ${statusTagStyle}`} style={tableStyles.tagWidth}>
          {statusLabel}
        </span>
      </td>

      <td>
        <span className="is-size-5">{item.queuer}</span>
      </td>

      <td className="has-text-centered">
        <ExpectedDate expectedDateString={item.expectedDate} />
      </td>
      <td className="has-text-aligned">
        <div className="buttons is-centered">
          {isDeletion && (
            <>
              <Loader type="ThreeDots" height="20" width="20" color="#098c8c" />
              <span className="ml-2 heading has-text-grey is-size-8">
                DELETING...
              </span>
            </>
          )}

          {!isDeletion && (
            <>
              <DeleteQueuerButton
                id={item.id}
                name={item.queuer}
                onDeletion={() => setIsDeletion(true)}
              />
              
              <ActionButton buttonStyles="is-dark is-outlined is-small" 
                  onActionClick={()=> onEdit(item)}>
                <FaEdit size={16} />
              </ActionButton>

              <StatusUpdateButton
                status={nextStatus}
                statusLabel={statusLabel}
                statusTagStyle={statusTagStyle}
                id={item.id}
                name={item.queuer}
              />
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

const ExpectedDate = ({ expectedDateString }) => {
  if (!expectedDateString) {
    return <span className="heading">N/A</span>;
  }

  const expectedDate = moment(expectedDateString, "MMM DD, yyyy - HH:mm");
  const isSameDay = expectedDate.isSame(moment(), "day");
  const hasTime = expectedDate.format("HH:mm") !== "00:00";
  const isPastDate = expectedDate.isBefore(moment());

  const className =
    isPastDate && (hasTime || !isSameDay) ? "has-text-danger" : "";

  const format = hasTime ? (isSameDay ? " HH:mm" : "ddd, MMM DD - HH:mm") : "ddd, MMM DD";

  return <span className={className}>{expectedDate.format(format)}</span>;
};

const tableStyles = {
  statusWidth: {
    width: "180px",
  },
  expectedWidth: {
    width: "200px",
  },
  actionWidth: {
    width: "225px",
  },
  tagWidth: {
    minWidth: "65px",
    fontWeight: "400"
  },
};
